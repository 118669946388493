import React from "react"
import Img from "../../../static/contact-side.png"
import { DotIcon } from "../Services-Page/SVGs"
import Heading from "../shared/Heading"
import "../shared/Hero/Hero.scss"
import Head from "../utils/Head"
import ContactForm from "./ContactForm"
import "./Footer.scss"
export default function ContactUs({ queries }) {
  return (
    <div
      className="contact-us-main"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-anchor-placement="top-center"
    >
      <Head
        metaTitle="Contact Us For Software Development Services - CodeFulcrum"
        title="Custom Software Development Services"
        desc="Looking for custom software development services? We can help you with that. We offer a wide range of services that can help you with your data engineering needs. Contact us today and let us know how we can help you."
      />
      <div className="container position-relative">
        <div className="row gx-5">
          <div className="col-12">
            <Heading variant="40" className="pb-2 text-capitalize">
              Ready to get started?
            </Heading>
            <div className="text">
              {!queries
                ? " Get in touch and our team will be right with you!"
                : "Submit the form and our team will be right with you! "}
            </div>
          </div>
          <div className="col-12 d-flex">
            <div className="row ">
              <div
                className="col-md-6 col-sm-12 d-flex flex-column "
                style={{ paddingRight: "1rem", paddingTop: "2.4rem" }}
              >
                {!queries ? (
                  <>
                    <ul className="sub-text pb-4">
                      <li>
                        <DotIcon />{" "}
                        <span>We will respond to you within 24 hours.</span>
                      </li>
                      <li>
                        <DotIcon />
                        <span>We’ll sign an NDA if requested.</span>
                      </li>
                      <li>
                        <div>
                          <DotIcon />{" "}
                        </div>
                        <span>
                          You'll be talking to product and tech experts (no
                          account managers).
                        </span>
                      </li>
                    </ul>
                    <div className=" d-flex justify-content-center  ">
                      <img
                        // src="../../../static/contact-side.png"
                        src={Img}
                        placeholder="none"
                        // formats={["auto", "webp", "avif"]}
                        alt="img"
                        style={{ width: "95%", marginRight: "1rem" }}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div
                className="col-md-6 col-sm-12 d-flex flex-column justify-content-center"
                style={{ paddingLeft: "1rem", paddingTop: "1rem" }}
              >
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
